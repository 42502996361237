<template>
  <CCardBody>
    <CDataTable border :items="items" :fields="fields">
      <td slot="service_name" slot-scope="{ item }">
        <b>{{ item.service_name }}</b>
        <br />
        {{ item.address }}
      </td>
      <td slot="booking_no" slot-scope="{ item }">
        <!-- {{ item.booking_no }} -->
        <CLink :href="'#/bookings/' + item.booking_id">
          {{ item.booking_no ? item.booking_no : '-' }}
        </CLink>
      </td>
      <td slot="job_id" slot-scope="{ item }">
        <span v-if="item.job_id">
          {{ item.job_id }}
        </span>
        <span v-else>-</span>
      </td>
      <td slot="payout_type" slot-scope="{ item }">
        <div>
          {{ item.payout_type }}
          <span v-if="item.payout_type == 'brought_forward'">
            <br />
            {{ item.from_to }}
          </span>
          <span v-else></span>
        </div>
      </td>
      <td slot="revenue_amount" slot-scope="{ item }">
        {{ item.received_amount | currency100 }}
      </td>

      <td slot="payout_amount" slot-scope="{ item }">
        {{ item.amount | currency100 }}
      </td>
      <td slot="updated_at" slot-scope="{ item }">
        {{ item.updated_at | moment(datetimeFormat) }}
      </td>
      <td slot="created_at" slot-scope="{ item }">
        {{ item.created_at | moment(datetimeFormat) }}
      </td>
    </CDataTable>
  </CCardBody>
</template>

<script>
import { Common } from '@/constants';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    return {
      datetimeFormat: Common.datetimeFormat,
      dateFormat: Common.dateFormat,
      fields: [
        {
          key: 'service_name',
          label: 'Service Name',
        },
        {
          key: 'booking_no',
          label: 'Booking No',
        },
        {
          key: 'job_id',
          label: 'JobID',
        },
        {
          key: 'payout_type',
          label: 'Payout_type',
        },
        {
          key: 'revenue_amount',
          label: 'Revenue',
        },
        {
          key: 'payout_amount',
          label: 'Payout',
        },
        {
          key: 'updated_at',
          label: 'Update At',
        },
        {
          key: 'created_at',
          label: 'Create At',
        },
      ],
    };
  },
  methods: {},
};
</script>
