<template>
  <div id="postServiceComponent">
    <CCard>
      <CCardHeader>
        <b>{{ title }}</b></CCardHeader
      >
      <CCardBody>
        <CDataTable
          hover
          striped
          border
          sorter
          :items="datas"
          :fields="fields"
          :items-per-page="25"
          :pagination="{ doubleArrows: false, align: 'center' }"
        >
          <td slot="category" slot-scope="{ item }">
            {{ item.category }}
          </td>
          <td slot="subcategory" slot-scope="{ item }">
            {{ item.subcategory }}
          </td>

          <td slot="action" slot-scope="{ item }">
            <CSwitch
              size="sm"
              labelOn="On"
              labelOff="Off"
              shape="pill"
              color="success"
              @change.native="
                onSwitchPostService(
                  item.subcategory_id,
                  !item.sub_category_disabled
                )
              "
              :checked="!item.sub_category_disabled"
            />
          </td>

          <template #show_details="{ item, index }">
            <td class="py-2">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="toggleDetails(item, index)"
              >
                {{ Boolean(item._toggled) ? 'Hide' : 'Show' }}
              </CButton>
            </td>
          </template>

          <template #details="{ item, index }">
            <CCollapse
              :show="Boolean(datas[index]._toggled)"
              :duration="collapseDuration"
            >
              <CCardBody>
                <CDataTable
                  border
                  striped
                  sorter
                  :items="item.services"
                  :fields="serviceFields"
                >
                  <td slot="disabled" slot-scope="{ item }">
                    <CBadge :color="item.disabled ? 'success' : 'primary'">
                      {{ item.disabled }}
                    </CBadge>
                  </td>
                  <td slot="category" slot-scope="{ item }">
                    {{ item.category.name }}
                  </td>
                  <td slot="subcategory" slot-scope="{ item }">
                    {{ item.subcategory.name }}
                  </td>
                  <td slot="base_price" slot-scope="{ item }">
                    {{ item.base_price | currency100 }}
                  </td>
                  <td slot="price" slot-scope="{ item }">
                    {{ item.price | currency100 }}
                  </td>
                  <td slot="updated_at" slot-scope="{ item }">
                    {{ item.updated_at | moment(datetimeFormat) }}
                  </td>
                  <td slot="action" slot-scope="{ item }">
                    <CSwitch
                      size="sm"
                      labelOn="On"
                      labelOff="Off"
                      shape="pill"
                      color="success"
                      @change.native="
                        onSwitchPostService(item.id, !item.disabled)
                      "
                      :checked="!item.disabled"
                    />
                  </td>
                </CDataTable>
              </CCardBody>
            </CCollapse>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { Common } from '@/constants';
import ProviderProxy from '@/proxies/provider.proxy';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    sp_id: {
      type: String,
      required: true,
    },
  },
  components: {},
  data: function () {
    return {
      datetimeFormat: Common.datetimeFormat,
      dateFormat: Common.dateFormat,
      datas: [],
      fields: [
        {
          key: 'category',
          label: 'Category',
        },
        {
          key: 'subcategory',
          label: 'Sub Category',
        },
        {
          key: 'action',
          label: 'On/Off SubCategory',
        },
        {
          key: 'show_details',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false,
        },
      ],
      serviceFields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'disabled',
          label: 'Disabled',
        },
        {
          key: 'category',
          label: 'Category',
        },
        {
          key: 'subcategory',
          label: 'Subcategory',
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'unit_type',
          label: 'UnitType',
        },
        {
          key: 'num_of_units',
          label: 'Units',
        },
        {
          key: 'base_price',
          label: 'Price Listed',
        },
        {
          key: 'price',
          label: 'Price Shown',
        },
        {
          key: 'updated_at',
          label: 'UpdatedDate',
        },
      ],
      collapseDuration: 0,
    };
  },
  computed: {},
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      ProviderProxy.getServices(this.sp_id).then(({ data }) => {
        let map = new Map();
        data.forEach((e) => {
          let key = `${e.category.name}-${e.subcategory.name}`;
          if (map.has(key)) {
            let x = map.get(key);
            x.services.push(e);
          } else {
            map.set(key, {
              key: key,
              subcategory_id: e.subcategory_id,
              category: e.category.name,
              subcategory: e.subcategory.name,
              sub_category_disabled: e.sub_category_disabled,
              services: [e],
            });
          }
        });

        let datas = Array.from(map)
          .map(([, v]) => ({
            category: v.category,
            key: v.key,
            subcategory_id: v.subcategory_id,
            services: v.services,
            sub_category_disabled: v.sub_category_disabled,
            subcategory: v.subcategory,
          }))
          .sort((a, b) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0));

        datas.forEach((v, i) => {
          v._toggled = this.datas[i] ? this.datas[i]._toggled : false;
        });

        this.datas = datas;
      });
    },
    toggleDetails(item, index) {
      this.datas[index]._toggled = !this.datas[index]._toggled;
      // this.$set(this.datas[index], '_toggled', !item._toggled);

      //update DOM
      this.collapseDuration = 100;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },

    async onSwitchPostService(subCategoryId, val) {
      await ProviderProxy.adminChangeStatusPostService(
        this.sp_id,
        subCategoryId,
        val ? 'disable' : 'enable'
      );

      this.$toast.success(`Updating is successfully`, {
        duration: 3000,
      });

      this.fetchData();
    },
  },
};
</script>
