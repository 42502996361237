<template>
  <div id="editProviderCategoryModal">
    <CModal
      :show.sync="showModal"
      size="lg"
      :centered="true"
      :closeOnBackdrop="false"
    >
      <CForm>
        <div class="form-row">
          <CCol tag="label" sm="3" class="col-form-label"> Categories </CCol>
          <CCol sm="9">
            <template v-for="(category, index) in fullCategories">
              <div class="form-row form-check" :key="index">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :id="category.id"
                  :value="category.id"
                  v-model="checkedCategories"
                />
                <label
                  :key="index"
                  :for="category.id"
                  class="form-check-label"
                  >{{ category.name }}</label
                >
              </div>
            </template>
            <!-- <span>Checked: {{ checkedCategories }}</span> -->
          </CCol>
        </div>
      </CForm>
      <template #header>
        <h5 class="modal-title">Edit Categories</h5>
        <CButtonClose @click="closeModal" />
      </template>
      <template #footer>
        <CButton @click="closeModal" color="secondary">Cancel</CButton>
        <CButton @click="onEdit" color="primary">Save</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    categories: {
      type: Array,
      required: true,
    },
    fullCategories: {
      type: Array,
      required: true,
    },
  },
  watch: {
    showModal: function () {
      this.checkedCategories = this.categories.map((x) => x.id);
    },
  },
  data: function () {
    return {
      checkedCategories: [],
    };
  },
  methods: {
    closeModal() {
      this.checkedCategories = [...this.categories];
      this.$emit('close');
    },
    onEdit() {
      this.$emit('save', this.checkedCategories);
    },
  },
};
</script>
