<template>
  <div id="bookingHistoryComponent">
    <CCard>
      <CCardHeader>
        <b>{{ title }} </b>
      </CCardHeader>
      <CCardBody>
        <CDataTable border striped :items="items" :fields="fields">
          <td slot="booking_no" slot-scope="{ item }">
            <CLink :href="'#/bookings/' + item.booking_id">
              {{ item.metadata['booking_no'] }}
            </CLink>
          </td>
          <td slot="booking_id" slot-scope="{ item }">
            <CLink :href="'#/bookings/' + item.booking_id">
              {{ item.booking_id }}
            </CLink>
          </td>
          <td slot="created_at" slot-scope="{ item }">
            {{ item.created_at | moment(datetimeFormat) }}
          </td>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { Common } from '@/constants';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      datetimeFormat: Common.datetimeFormat,
      dateFormat: Common.dateFormat,
    };
  },
};
</script>
