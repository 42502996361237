import BaseProxy from './base.proxy';

class UploadProxy extends BaseProxy {
  getUploadFileURL(num) {
    return this.get(`/admin/util/url/${num}/document`);
  }

  getUploadImageURL(num) {
    return this.get(`/util/url/${num}`);
  }

  uploadFile(files) {
    let num = files.length;
    let tasks = [];
    let results = [];
    return this.getUploadFileURL(num)
      .then((res) => {
        let urls = res.data;
        for (let i = 0; i < num; i++) {
          const url = new URL(urls[i]);
          results.push(`${url.origin}${url.pathname}`);
          tasks.push(this.upload(files[i], urls[i]));
        }
        return Promise.all(tasks);
      })
      .then(() => {
        console.debug('uploadFile', results);
        return results;
      });
  }
  uploadImage(files) {
    let num = files.length;
    let tasks = [];
    let results = [];
    return this.getUploadImageURL(num)
      .then((res) => {
        let urls = res.data;
        for (let i = 0; i < num; i++) {
          const url = new URL(urls[i]);
          results.push(`${url.origin}${url.pathname}`);
          tasks.push(this.upload(files[i], urls[i]));
        }
        return Promise.all(tasks);
      })
      .then(() => {
        console.debug('uploadImage', results);
        return results;
      });
  }
}

export default new UploadProxy();
