<template>
  <div id="revenueListComponent">
    <CCard>
      <CCardHeader>
        <CRow class="justify-content-between px-3">
          <span v-if="sp_id">
            <div class="d-flex">
              <div class="mx-2">
                Revenue:
                <b>{{ revenueData.totalReceivedAmount | currency100 }}</b>
              </div>
              <div class="mx-2">
                Pay out:
                <b>{{ revenueData.totalPayoutAmount | currency100 }}</b>
              </div>
              <div class="mx-2">
                Paid out:
                <b>{{ revenueData.totalPaidoutAmount | currency100 }}</b>
              </div>
            </div>
          </span>
          <span v-else>
            <div class="d-flex">
              <div class="mx-2">
                Net sales:
                <b>{{ revenueData.totalGrossRevenue | currency100 }}</b>
              </div>
              <div class="mx-2">
                Stripe fees:
                <b>{{ revenueData.totalStripeFee | currency100 }}</b>
              </div>
              <div class="mx-2">
                Payout of SP:
                <b>{{ revenueData.totalPayoutAmount | currency100 }}</b>
              </div>
              <div class="mx-2">
                Payout of CN:
                <b>{{ revenueData.totalAvailablePayout | currency100 }}</b>
              </div>
            </div>
          </span>

          <div class="d-flex">
            <span v-if="sp_id">
              <download-csv
                class="btn btn-default"
                :data="csvDatas"
                :labels="{
                  type: 'Type',
                  booking_id: 'Booking ID',
                  datetime: 'Date and time',
                  received_amount: 'Revenue',
                  payout_amount: 'Pay out',
                  paidout_amount: 'Paid Out',
                }"
                :fields="[
                  'type',
                  'booking_id',
                  'datetime',
                  'received_amount',
                  'payout_amount',
                  'paidout_amount',
                ]"
                :name="csvFileName"
              >
                <CIcon name="cil-cloud-download" />
                Download
              </download-csv>
            </span>
            <span v-else>
              <download-csv
                class="btn btn-default"
                :data="csvDatas"
                :labels="{
                  type: 'Type',
                  booking_id: 'Booking ID',
                  datetime: 'Date and time',
                  gross_revenue: 'Net sales',
                  stripe_fee: 'Stripe fees',
                  payout_amount: 'Pay out of SP',
                  available_payout: 'Pay out of CN',
                }"
                :fields="[
                  'type',
                  'booking_id',
                  'datetime',
                  'gross_revenue',
                  'stripe_fee',
                  'payout_amount',
                  'available_payout',
                ]"
                :name="csvFileName"
              >
                <CIcon name="cil-cloud-download" />
                Download
              </download-csv>
            </span>
            <CInput
              style="width: 400px"
              class="my-0 px-0"
              :placeholder="`Search BookingID`"
              :value.sync="search.value"
            >
              <template #append>
                <CButton color="primary" @click="doApplyFilter">Search</CButton>
                <CButton
                  class="ml-2"
                  size="sm"
                  color="info"
                  variant="outline"
                  @click="isShowFilter = !isShowFilter"
                >
                  <CIcon :name="isShowFilter ? 'cil-x' : 'cil-arrow-bottom'" />
                  Filter</CButton
                >
              </template>
            </CInput>
          </div>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CCollapse :show="isShowFilter">
          <CCard body-wrapper>
            <CRow>
              <h5 class="ml-3 flex-grow-1">Filter</h5>
              <CButton
                class="ml-auto m-2"
                variant="outline"
                color="info"
                @click="doResetFilter"
                >Reset</CButton
              >
              <CButton class="m-2" color="info" @click="doApplyFilter"
                >Apply</CButton
              >
            </CRow>
            <CRow>
              <CCol>
                <treeselect
                  v-model="filter.type"
                  :multiple="true"
                  :options="filterData.type"
                  placeholder="Select status"
                />
              </CCol>

              <CCol>
                <date-picker
                  v-model="filter.dateRangeSelected"
                  range
                  format="YYYY-MM-DD"
                  placeholder="Created date"
                ></date-picker>
              </CCol>
            </CRow>
          </CCard>
        </CCollapse>
        <CDataTable
          hover
          striped
          border
          sorter
          :items="payoutDatas"
          :fields="fields"
          :items-per-page="25"
          :pagination="{ doubleArrows: false, align: 'center' }"
        >
          <td slot="booking_id" slot-scope="{ item }">
            <span v-if="item.booking_id">
              <CLink :href="'#/bookings/' + item.booking_id">
                {{ item.metadata.booking_no }}
              </CLink>
            </span>
            <span v-else>-</span>
          </td>

          <td slot="job_id" slot-scope="{ item }">
            <span v-if="item.metadata.job_id">
              {{ item.metadata.job_id }}
            </span>
            <span v-else>-</span>
          </td>
          <td slot="type" slot-scope="{ item }">
            {{ item.payout_type }}
          </td>

          <td slot="status" slot-scope="{ item }">
            {{ item.metadata.status }}
          </td>

          <td slot="payout_day" slot-scope="{ item }">
            {{ item.payout_day | moment(datetimeFormat) }}
          </td>

          <td slot="created_at" slot-scope="{ item }">
            {{ item.created_at | moment(datetimeFormat) }}
          </td>

          <td slot="revenue" slot-scope="{ item }">
            {{ item.received_amount | currency100 }}
          </td>
          <td slot="gross_revenue" slot-scope="{ item }">
            {{ item.gross_revenue | currency100 }}
          </td>
          <td slot="stripe_fee" slot-scope="{ item }">
            {{ item.stripe_fee | currency100 }}
          </td>
          <td slot="payout_amount" slot-scope="{ item }">
            {{ item.payout_amount | currency100 }}
          </td>
          <td slot="available_payout" slot-scope="{ item }">
            {{ item.available_payout | currency100 }}
          </td>
          <td slot="paidout_amount" slot-scope="{ item }">
            {{ item.paidout_amount | currency100 }}
          </td>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { Common } from '@/constants';
import AdminProxy from '@/proxies/admin.proxy';
import moment from 'moment';
import { FormatCurrency100, Capitalize } from '@/utils/utils';

export default {
  props: {
    sp_id: {
      type: String,
      required: true,
    },
    csvFileName: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  computed: {},
  mounted() {
    this.doApplyFilter();
  },
  data: function () {
    return {
      datetimeFormat: Common.datetimeFormat,
      dateFormat: Common.dateFormat,
      payoutDatas: [],
      csvDatas: [],
      isShowFilter: false,
      search: {
        value: '',
      },
      filter: {
        type: null,
        dateRangeSelected: null,
      },
      filterData: {
        type: [
          { id: 'transaction_fee', label: 'TransactionFee' },
          { id: 'cancellation_fee', label: 'CancellationFee' },
          { id: 'late_cancellation', label: 'LateCancellation' },
          { id: 'onsite', label: 'Onsite' },
          { id: 'reversal', label: 'Reversal' },
          {
            id: 'provider_cancellation_fee',
            label: 'ProviderCancellationFee',
          },
        ],
      },
      revenueData: {
        totalReceivedAmount: 0,
        totalGrossRevenue: 0,
        totalStripeFee: 0,
        totalPayoutAmount: 0,
        totalAvailablePayout: 0,
        totalPaidoutAmount: 0,
      },
    };
  },
  methods: {
    doApplyFilter() {
      let query = {};

      if (this.sp_id) {
        query = {
          provider_id: this.sp_id,
        };
      }

      if (this.search.value?.length > 0) {
        query = {
          ...query,
          booking_no: this.search.value,
        };
      }

      if (this.filter.type?.length > 0) {
        query = {
          ...query,
          type: this.filter.type,
        };
      }

      if (
        this.filter.dateRangeSelected?.length > 0 &&
        this.filter.dateRangeSelected[0] &&
        this.filter.dateRangeSelected[1]
      ) {
        query = {
          ...query,
          from: moment(this.filter.dateRangeSelected[0])
            .startOf('day')
            .format('YYYY-MM-DD'),
          to: moment(this.filter.dateRangeSelected[1])
            .endOf('day')
            .format('YYYY-MM-DD'),
        };
      }

      AdminProxy.getFinances(query).then(({ data }) => {
        this.payoutDatas = data;
        this.processCSVData();
      });
    },
    doResetFilter() {
      this.filter.type = null;
      this.filter.dateRangeSelected = null;

      this.doApplyFilter();
    },
    processCSVData() {
      let total_received_amount = 0;
      let total_gross_revenue = 0;
      let total_stripe_fee = 0;
      let total_payout_amount = 0;
      let total_available_payout = 0;
      let total_paidout_amount = 0;

      this.payoutDatas.forEach((x) => {
        total_received_amount += x.received_amount;
        total_gross_revenue += x.gross_revenue;
        total_stripe_fee += x.stripe_fee;
        total_payout_amount += x.payout_amount;
        total_available_payout += x.available_payout;
        total_paidout_amount += x.paidout_amount;

        this.csvDatas.push({
          type: Capitalize(x.payout_type),
          booking_id: x.metadata.booking_no,
          datetime: moment(x.created_at).format('YYYY-MM-DD HH:mm:ss'),
          received_amount: FormatCurrency100(x.received_amount),
          gross_revenue: FormatCurrency100(x.gross_revenue),
          stripe_fee: FormatCurrency100(x.stripe_fee),
          payout_amount: FormatCurrency100(x.payout_amount),
          available_payout: FormatCurrency100(x.available_payout),
          paidout_amount: FormatCurrency100(x.paidout_amount),
        });
      });

      this.csvDatas.push({
        type: 'Total',
        received_amount: FormatCurrency100(total_received_amount),
        gross_revenue: FormatCurrency100(total_gross_revenue),
        stripe_fee: FormatCurrency100(total_stripe_fee),
        payout_amount: FormatCurrency100(total_payout_amount),
        available_payout: FormatCurrency100(total_available_payout),
        paidout_amount: FormatCurrency100(total_paidout_amount),
      });

      this.revenueData = {
        totalReceivedAmount: total_received_amount,
        totalGrossRevenue: total_gross_revenue,
        totalStripeFee: total_stripe_fee,
        totalPayoutAmount: total_payout_amount,
        totalAvailablePayout: total_available_payout,
        totalPaidoutAmount: total_paidout_amount,
      };
    },
  },
};
</script>
