<template>
  <div id="editProviderInfoModal">
    <CModal
      :show.sync="showModal"
      size="lg"
      :centered="true"
      :closeOnBackdrop="false"
    >
      <CForm ref="xform">
        <CImg
          v-bind:src="
            uploadFile.url || provider.profile_url || 'img/no-image.png'
          "
          width="80px"
          height="80px"
          shape="rounded"
        />
        <CInputFile
          ref="inputFile"
          label="Logo"
          horizontal
          accept=".jpg,.jpeg,.png,"
          @change="onFileChange"
        />
        <CSelect
          horizontal
          label="BusinessType"
          :value.sync="provider.type"
          :options="businessType.options"
          @change="onChangeBusinessType($event)"
        />
        <CInput
          label="Alias (*)"
          horizontal
          placeholder="Name..."
          :value.sync="provider.name"
          required
          was-validated
        />
        <CInput
          label="BusinessName (*)"
          horizontal
          placeholder="Business name..."
          :value.sync="provider.business_name"
          required
          was-validated
        />
        <CInput
          label="Address (*)"
          horizontal
          placeholder="Address..."
          :value.sync="provider.address"
          required
          was-validated
        />
        <CInput
          label="UnitNumber"
          horizontal
          placeholder="UnitNumber..."
          :value.sync="provider.unit_number"
          was-validated
        />
        <CInput
          label="PostalCode (*)"
          horizontal
          placeholder="PostalCode..."
          :value.sync="provider.postal_code"
          required
          was-validated
        />
        <CInput
          label="RegisteredID (*)"
          horizontal
          placeholder="RegisteredID..."
          :value.sync="provider.registered_id"
          required
          was-validated
        />
        <CInput
          label="GST"
          horizontal
          placeholder="GST..."
          :value.sync="provider.gst"
          was-validated
          v-show="isShowGST ? isShowGST : provider.type === 'company'"
        />
        <CInput
          label="Years of experience (*)"
          type="number"
          horizontal
          placeholder="YOE..."
          :value.sync="provider.yoe"
          required
          was-validated
        />
        <CTextarea
          horizontal
          label="Description (*)"
          :value.sync="provider.description"
          placeholder="Description..."
          rows="3"
          required
          was-validated
        />
        <CInput
          label="Insurance expiry date"
          type="date"
          horizontal
          was-validated
          :value.sync="provider.insurance_expiry_date"
          v-show="provider.type === 'company'"
        />
      </CForm>
      <template #header>
        <h5 class="modal-title">Edit Provider</h5>
        <CButtonClose @click="onClose" />
      </template>
      <template #footer>
        <CButton @click="onClose" color="secondary">Cancel</CButton>
        <CButton @click="onUpdateData" color="primary">Save</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import UploadProxy from '@/proxies/upload.proxy';

export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    providerData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    showModal: function () {
      this.provider = { ...this.providerData };
    },
  },
  data: function () {
    return {
      uploadFile: {
        url: null,
        file: null,
      },
      businessType: {
        options: [
          { value: 'company', label: 'Company' },
          { value: 'freelancer', label: 'Freelancer' },
        ],
      },
      isShowGST: undefined,
      provider: {},
    };
  },

  methods: {
    onResetData() {
      this.uploadFile = { url: null, file: null };
    },
    onClose() {
      this.onResetData();
      this.$emit('close-modal');
    },
    onChangeBusinessType(e) {
      this.isShowGST = e.target.value === 'company';
    },

    async onUpdateData() {
      if (this.uploadFile?.url && this.uploadFile?.file) {
        let urls = await UploadProxy.uploadImage([this.uploadFile.file]);
        let url = urls[0];
        this.provider.profile_url = url;
      }

      let data = {
        name: this.provider.name,
        type: this.provider.type,
        registered_id: this.provider.registered_id,
        gst: this.provider.type === 'company' ? this.provider.gst : '',
        yoe: parseInt(this.provider.yoe),
        business_name: this.provider.business_name,
        description: this.provider.description,
        profile_url: this.provider.profile_url,
        address: this.provider.address,
        unit_number: this.provider.unit_number,
        postal_code: this.provider.postal_code,
        insurance_expiry_date: this.provider.insurance_expiry_date,
      };
      this.$emit('save', data);
    },
    onFileChange(files) {
      if (files?.length <= 0) return;
      let file = files[0];

      this.uploadFile = {
        file: file,
        url: URL.createObjectURL(file),
        fileName: file.name,
        size: file.size,
      };
    },
  },
  name: 'EditProviderInfoModal',
};
</script>
